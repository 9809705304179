import { BuildwiseApplication } from "../BuildwiseApplication/BuildwiseApplication";
import { BuildwiseUser } from "./BuildwiseUser";
import { PrismicDocument } from "@prismicio/types";

export type ValidLocale = "nl-be" | "fr-be";
export type DocumentState = "idle" | "loading" | "loaded" | "failed";

export interface BuildwiseState {
    instance?: BuildwiseApplication;

    user: BuildwiseUser;
    isAuthenticated: boolean;
    isAdmin: boolean;
    useRedirect: boolean;
    onLoginSuccess?: () => void;

    feedbackForm: boolean;

    currentLocale: ValidLocale;
    documents: Record<string, PrismicDocument>;
    documentsState: Record<string, DocumentState>;
    documentsErrors: Record<string, string>;
}

export const initialState: BuildwiseState = {
    user: { id: "guest-user", name: "Guest", email: "" },
    isAuthenticated: false,
    isAdmin: false,
    useRedirect: false,

    feedbackForm: false,

    currentLocale: "nl-be",
    documents: {},
    documentsState: {},
    documentsErrors: {},
};
