import { useContext, useEffect, useState } from "react";
import { PrismicDocument } from "@prismicio/types";
import { isAlreadyRequested, locales } from "../../components/core/BuildwiseProvider/LocaleHandler";

import { BuildwiseContext } from "../../components/core/BuildwiseProvider/BuildwiseContext";
import { DocumentState, ValidLocale } from "../../components/core/BuildwiseProvider/BuildwiseState";
import { requestPrismicDocument } from "../../components/core/BuildwiseProvider/BuildwiseReducer";

type usePrismicHook = [document: PrismicDocument | null, state: { state: DocumentState; error: string }];

const usePrismic = (documentType: string): usePrismicHook => {
    const { state, dispatch } = useContext(BuildwiseContext);
    const identifier = `${documentType}-${state.currentLocale}`;

    const [document, setDocument] = useState<PrismicDocument>(state.documents[identifier]);
    const [documentState, setDocumentState] = useState<DocumentState>(state.documentsState[identifier] ?? "idle");
    const [documentError, setDocumentError] = useState<string>(state.documentsErrors[identifier] ?? "");

    useEffect(() => {
        locales.forEach((locale) => {
            if (isAlreadyRequested(state.documentsState, documentType, locale)) return;
            requestDocument(documentType, locale);
        });
    }, [documentType]);

    useEffect(() => {
        const identifier = `${documentType}-${state.currentLocale}`;
        setDocument(state.documents[identifier]);
        setDocumentState(state.documentsState[identifier]);
        setDocumentError(state.documentsErrors[identifier]);
    }, [state.currentLocale, state.documentsState]);

    const requestDocument = (documentType: string, locale: ValidLocale) => {
        dispatch(requestPrismicDocument(documentType, locale));
    };

    return [
        document,
        {
            state: documentState,
            error: documentError,
        },
    ];
};

export default usePrismic;
