import { BrowserAuthError, IPublicClientApplication } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext } from "react";
import { BuildwiseContext } from "../../components/core/BuildwiseProvider/BuildwiseContext";
import { login } from "../../components/core/BuildwiseProvider/BuildwiseReducer";
import { BuildwiseUser } from "../../components/core/BuildwiseProvider/BuildwiseUser";

export interface BuildwiseAuthenticationHook {
    isAuthenticated: boolean;
    isAdmin: boolean;
    user?: BuildwiseUser;
    instance: IPublicClientApplication,
    login: (onSuccess?: () => void, onFailed?: () => void, onCancelled?: () => void) => void;
    loginRedirect: () => void;
    logout: () => void;
}

const useAuthentication = (): BuildwiseAuthenticationHook => {
    const { state, dispatch } = useContext(BuildwiseContext);
    const { instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const handleLogin = (onSuccess?: () => void, onFailed?: () => void, onCancelled?: () => void) => {
        if (isAuthenticated) return;

        dispatch(login(onSuccess));
        instance.loginPopup({ redirectUri: "", scopes: state.instance!.scopes }).catch((err: any) => {
            if (err instanceof BrowserAuthError) {
                const authError = err as BrowserAuthError;
                if (authError.name === "user_cancelled") {
                    onCancelled && onCancelled();
                }
            }

            onFailed && onFailed();
        });
    };

    const handleLoginRedirect = () => {
        if (isAuthenticated) return;

        instance.loginRedirect({ scopes: state.instance!.scopes });
    };

    const handleLogout = () => {
        if (!isAuthenticated) return;

        instance.logoutRedirect();
    };

    return {
        isAuthenticated: isAuthenticated,
        isAdmin: state.isAdmin,
        user: state.user,
        instance: instance,
        login: handleLogin,
        loginRedirect: handleLoginRedirect,
        logout: handleLogout,
    };
};

export default useAuthentication;
