import { HTMLAttributes, createContext, useContext, useEffect, ReactNode } from "react";
import Backdrop from "../../utils/Backdrop/Backdrop";
import Portal from "../../utils/Portal/Portal";
import { useMediaQuery } from "react-responsive";

import styles from "./Modal.module.css";

interface ModalState {
    onClose?: () => void;
}

const ModalContext = createContext<ModalState>({});

export const useModalContext = () => {
    const context = useContext(ModalContext);
    if (!context) throw new Error(`Modal compound components cannot be rendered outside the Modal component.`);

    return context;
};

export interface ModalProps extends HTMLAttributes<HTMLDivElement> {
    /** Determine the max-width of the modal. The modal width grows with the size of the screen unless a number in pixels is specified. */
    width?: "small" | "normal" | "large" | number;
    /** If true, the Modal is open. */
    open: boolean;
    /** Callback fired when the component requests to be closed. */
    onClose?: () => void;
    /** Modal children, usually the included sub-components. */
    children: ReactNode;
    /** If true, the backdrop is displayed when the Modal is open. */
    backdrop?: boolean;
}

const Modal = ({ width: size, backdrop, open, onClose, className, style, children, ...other }: ModalProps) => {
    const activeStyles = [styles.modal];
    const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

    if (!size) activeStyles.push(styles.normal);
    else if (typeof size === "string") activeStyles.push(styles[size]);
    else if (!isSmallScreen) style = { ...style, width: `${size}px`, minWidth: `${size}px` };

    if (className) activeStyles.push(className);

    useEffect(() => {
        return () => {
            document.body.classList.toggle("noscroll", false);
        };
    }, []);

    useEffect(() => {
        document.body.classList.toggle("noscroll", open);
    }, [open]);

    return (
        <ModalContext.Provider value={{ onClose }}>
            {open && (
                <Portal>
                    {Boolean(backdrop) ? (
                        <Backdrop>
                            <div className={activeStyles.join(" ")} style={style} {...other}>
                                {children}
                            </div>
                        </Backdrop>
                    ) : (
                        <div className={styles.container}>
                            <div className={activeStyles.join(" ")} style={style} {...other}>
                                {children}
                            </div>
                        </div>
                    )}
                </Portal>
            )}
        </ModalContext.Provider>
    );
};

export default Modal;
