import { HTMLAttributes, ReactNode } from "react";
import { X } from "react-feather";
import { useModalContext } from "./Modal";

import styles from "./Modal.module.css";

export interface ModalHeaderProps extends HTMLAttributes<HTMLDivElement> {
    /** Header of the modal */
    children: ReactNode;
    /** If true, adds a close button to the header. */
    closeButton?: boolean;
}

const ModalHeader = ({ closeButton, children, className, ...other }: ModalHeaderProps) => {
    const { onClose } = useModalContext();
    const activeStyles = [styles.header];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
            {closeButton && (
                <div id="modal-close-button" className={styles.close} onClick={onClose}>
                    <X />
                </div>
            )}
        </div>
    );
};

export default ModalHeader;
