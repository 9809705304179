import Separator from "../Separator/Separator";
import { ReactNode, useContext, useEffect, useState } from "react";
import { PrismicRichText } from "@prismicio/react";

import usePrismic from "../../../hooks/usePrismic/usePrismic";

import Modal from "../../feedback/Modal/Modal";
import ModalBody from "../../feedback/Modal/ModalBody";
import Button from "../../inputs/Button/Button";
import styles from "./CookieConsent.module.css";
import Checkbox from "../../inputs/Checkbox/Checkbox";
import { ChevronDown, ChevronUp } from "react-feather";
import { BuildwiseContext } from "../../core/BuildwiseProvider/BuildwiseContext";

export interface CookieInfoPanelProps {
    children: ReactNode;
    text: string;
    onStateChange?: (state: boolean) => void;
    checked: boolean;
    disabled?: boolean;
}

const CookieInfoPanel = ({ children, text, onStateChange, checked, disabled }: CookieInfoPanelProps) => {
    const [open, setOpen] = useState(false);
    return (
        <div>
            <div className={styles.panelHolder}>
                <div className={styles.checkBoxHolder} style={{ width: "70%" }}>
                    <div
                        style={{ width: "100%", textAlign: "start", fontWeight: "bold" }}
                        onClick={() => setOpen(!open)}
                    >
                        {text}
                    </div>
                </div>
                <div
                    onClick={() => setOpen(!open)}
                    style={{ display: "flex", justifyContent: "flex-end", width: "30%", cursor: "pointer" }}
                >
                    <Checkbox
                        disabled={disabled}
                        checked={checked}
                        onChange={() => {
                            onStateChange && onStateChange(!checked);
                        }}
                    />
                    {open ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </div>
            </div>
            <div className={open ? styles.panelOpen : styles.panelClosed}>{children}</div>
        </div>
    );
};

const CookieConsent = () => {
    const [document, { state: prismicState }] = usePrismic("bbriui");
    const { state } = useContext(BuildwiseContext);
    const cookiesConfig = state?.instance?.cookies;
    const [open, setOpen] = useState(false);
    const [activeTab, setActiveTab] = useState(0);

    const [necessary] = useState(true);
    const [performance, setPerformance] = useState(false);
    const [functional, setFunctional] = useState(false);
    const [targeting, setTargeting] = useState(false);
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        if (!cookiesConfig) return;
        let cookieKey = `${cookiesConfig.name}=`;
        if (window.document.cookie.split(";").some((item) => item.trim().startsWith(cookieKey))) {
            const cookieConsent = window.document.cookie?.split(";").find((item) => item.trim().startsWith(cookieKey));
            const consentData = JSON.parse(cookieConsent?.split("=")[1] || "");

            setPerformance(consentData.performance);
            setFunctional(consentData.functional);
            setTargeting(consentData.targeting);
            setDate(new Date(consentData.date));

            cookiesConfig.callback(necessary, performance, functional, targeting, new Date(consentData.date));
        } else {
            setOpen(true);
        }
    }, []);

    const onAcceptSelection = () => {
        setChanges(necessary, performance, functional, targeting);
        setOpen(false);
    };

    const onDeny = () => {
        setOpen(false);
        setChanges(true, false, false, false);
    };

    const setCookie = (name: string, value: string, days: number = 365) => {
        if (!cookiesConfig) return;
        let expires = "";
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = "; expires=" + date.toUTCString();
        }
        window.document.cookie = name + "=" + (value || "") + expires + "; path=/";
    };

    const setChanges = (necessary: boolean, performance: boolean, functional: boolean, targeting: boolean) => {
        if (!cookiesConfig) return;
        setCookie(
            cookiesConfig.name,
            JSON.stringify({ necessary, performance, functional, targeting, date: date.toISOString() }),
            365
        );
        cookiesConfig.callback(necessary, performance, functional, targeting, date);
        setOpen(false);
        setPerformance(performance);
        setFunctional(functional);
        setTargeting(targeting);
    };

    const onAccept = () => {
        setPerformance(true);
        setFunctional(true);
        setTargeting(true);
        setOpen(false);
        setChanges(true, true, true, true);
    };

    const getTabButtonStyle = (tab: number) => {
        if (activeTab === tab) {
            return [styles.borderlessSelected, styles.borderless].join(" ");
        }
        return styles.borderless;
    };

    return prismicState === "loaded" ? (
        <>
            <div className={styles.icons} onClick={() => setOpen(true)}>
                <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="CookieOutlinedIcon">
                    <circle cx="10.5" cy="8.5" r="1.5"></circle>
                    <circle cx="8.5" cy="13.5" r="1.5"></circle>
                    <circle cx="15" cy="15" r="1"></circle>
                    <path d="M21.95 10.99c-1.79-.03-3.7-1.95-2.68-4.22-2.97 1-5.78-1.59-5.19-4.56C7.11.74 2 6.41 2 12c0 5.52 4.48 10 10 10 5.89 0 10.54-5.08 9.95-11.01zM12 20c-4.41 0-8-3.59-8-8 0-3.31 2.73-8.18 8.08-8.02.42 2.54 2.44 4.56 4.99 4.94.07.36.52 2.55 2.92 3.63C19.7 16.86 16.06 20 12 20z"></path>
                </svg>
            </div>
            <Modal open={open} backdrop className={styles.cookieModal}>
                <div className={styles.cookieTopButtonHolder}>
                    <Button
                        className={getTabButtonStyle(0)}
                        onClick={() => {
                            setActiveTab(0);
                        }}
                        variant={"secondary"}
                    >
                        {document!.data.cookie_consent_tab_permissions}
                    </Button>
                    <Button
                        className={getTabButtonStyle(1)}
                        onClick={() => {
                            setActiveTab(1);
                        }}
                        variant={"secondary"}
                    >
                        {document!.data.cookie_consent_tab_preferences}
                    </Button>
                </div>
                <ModalBody className={styles.cookieModalBody}>
                    {activeTab === 0 ? (
                        <div style={{ paddingBottom: "22px", marginTop: "10px" }}>
                            <PrismicRichText field={document!.data.cookie_consent_text} linkResolver={() => "/"} />
                        </div>
                    ) : (
                        <div>
                            <CookieInfoPanel
                                text={document!.data.cookie_consent_necessary}
                                checked={necessary}
                                disabled={true}
                            >
                                {document!.data.cookie_consent_necessary_description}
                            </CookieInfoPanel>
                            <Separator className={styles.cookieSeperator} />
                            <CookieInfoPanel
                                text={document!.data.cookie_consent_performance}
                                onStateChange={(state: boolean) => {
                                    setPerformance(state);
                                }}
                                checked={performance}
                            >
                                {document!.data.cookie_consent_performance_description}
                            </CookieInfoPanel>
                            <Separator className={styles.cookieSeperator} />
                            <CookieInfoPanel
                                text={document!.data.cookie_consent_functional}
                                onStateChange={(state: boolean) => {
                                    setFunctional(state);
                                }}
                                checked={functional}
                            >
                                {document!.data.cookie_consent_functional_description}
                            </CookieInfoPanel>
                            <Separator className={styles.cookieSeperator} />
                            <CookieInfoPanel
                                text={document!.data.cookie_consent_targetting}
                                onStateChange={(state: boolean) => {
                                    setTargeting(state);
                                }}
                                checked={targeting}
                            >
                                {document!.data.cookie_consent_targetting_description}
                            </CookieInfoPanel>
                        </div>
                    )}
                </ModalBody>
                <Separator className={styles.cookieSeperatorFooter} />
                <div className={styles.modalFooter}>
                    <Button id="cookie-consent-more-info-button" variant="secondary" onClick={() => onDeny()}>
                        {document!.data.cookie_consent_deny}
                    </Button>
                    {activeTab === 0 ? (
                        <Button variant="secondary" onClick={() => setActiveTab(1)}>
                            {document!.data.cookie_consent_edit}
                        </Button>
                    ) : (
                        <Button variant="secondary" onClick={() => onAcceptSelection()}>
                            {document!.data.cookie_consent_accept_selection}
                        </Button>
                    )}
                    <Button id="cookie-consent-accept-button" variant="primary" onClick={() => onAccept()}>
                        {document!.data.cookie_consent_button_accept_text}
                    </Button>
                </div>
            </Modal>
        </>
    ) : null;
};

export default CookieConsent;
