import { HTMLAttributes, ReactNode } from "react";
import ButtonStartIcon from "./ButtonStartIcon";
import ButtonEndIcon from "./ButtonEndIcon";

import styles from "./Button.module.css";

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    /** Variant of the button. */
    variant?: "primary" | "secondary" | "tertiary" | "action";
    /** If true, the button will use 100% of the width of its container. */
    block?: boolean;
    /** If true, mouse events are disabled and the button appears faded. */
    disabled?: boolean;
    /** An icon to render before the content of the button. */
    startIcon?: ReactNode;
    /** An icon to render behind the content of the button. */
    endIcon?: ReactNode;
    /** The text to appear on the button. */
    children: ReactNode;
}

const Button = ({
    variant,
    block,
    disabled,
    className,
    children,
    startIcon: startIconProp,
    endIcon: endIconProp,
    ...other
}: ButtonProps) => {
    const classNames = [styles.button, styles[variant ?? "primary"]];

    if (disabled) classNames.push(styles.disabled);
    if (block) classNames.push(styles.block);
    if (className) classNames.push(className);

    const startIcon = startIconProp && <ButtonStartIcon>{startIconProp}</ButtonStartIcon>;
    const endIcon = endIconProp && <ButtonEndIcon>{endIconProp}</ButtonEndIcon>;

    return (
        <button className={classNames.join(" ")} {...other}>
            {startIcon}
            <span className={styles.text}>{children}</span>
            {endIcon}
        </button>
    );
};

export default Button;
