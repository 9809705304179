import { ReactNode } from "react";
import styles from "./Header.module.css";

export interface ApplicationTitleProps {
    icon?: ReactNode;
    children?: string;
}

const ApplicationTitle = ({ icon, children }: ApplicationTitleProps) => {
    return (
        <div className={styles.application} id="site-title">
            {icon}
            {children}
        </div>
    );
};

export default ApplicationTitle;
