import { ReactNode } from "react";

export enum ActionType {
    AddBreadcrumb,
    RemoveBreadcrumb,
}

export interface AddBreadcrumb {
    type: ActionType.AddBreadcrumb;
    payload: { id: string; breadcrumb?: ReactNode };
}
export interface RemoveBreadcrumb {
    type: ActionType.RemoveBreadcrumb;
    payload: { id: string };
}

export type HeaderActions = AddBreadcrumb | RemoveBreadcrumb;
