import { createContext, Dispatch } from "react";

import { BuildwiseActions } from "./BuildwiseActions";
import { BuildwiseState, initialState } from "./BuildwiseState";

export const BuildwiseContext = createContext<{
    state: BuildwiseState;
    dispatch: Dispatch<BuildwiseActions>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
