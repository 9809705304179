import { PrismicDocument } from "@prismicio/types";
import {
    ActionType,
    Authenticate,
    BuildwiseActions,
    LoadPrismicDocument,
    Login,
    Logout,
    RequestPrismicDocument,
    SetFeedbackFormVisibility,
    SetLocale,
    SetPrismicDocument,
    SetPrismicDocumentError,
    SetUseRedirect,
} from "./BuildwiseActions";
import { BuildwiseState, ValidLocale } from "./BuildwiseState";
import { BuildwiseUser } from "./BuildwiseUser";

export const BuildwiseReducer = (state: BuildwiseState, action: BuildwiseActions): BuildwiseState => {
    switch (action.type) {
        case ActionType.Authenticate:
            let user = state.user;
            if (user?.id !== action.payload.user.id) {
                user = action.payload.user;
            }

            return {
                ...state,
                user,
                isAuthenticated: true,
                isAdmin: action.payload.isAdmin,
                onLoginSuccess: undefined,
            };

        case ActionType.SetUseRedirect:
            return { ...state, useRedirect: action.payload.useRedirect };

        case ActionType.Login:
            return {
                ...state,
                onLoginSuccess: action.payload.onSuccess,
            };

        case ActionType.Logout:
            return {
                ...state,
                user: { id: "guest-user", name: "Guest", email: "" },
                isAuthenticated: false,
                onLoginSuccess: undefined,
            };

        case ActionType.SetFeedbackFormVisibility:
            return {
                ...state,
                feedbackForm: action.payload.visible,
            };

        case ActionType.RequestPrismicDocument: {
            const documentsState = { ...state.documentsState };
            const identifier = `${action.payload.documentType}-${action.payload.locale}`;
            documentsState[identifier] = "idle";

            return { ...state, documentsState };
        }

        case ActionType.LoadPrismicDocument: {
            const documentsState = { ...state.documentsState };
            const identifier = `${action.payload.documentType}-${action.payload.locale}`;
            documentsState[identifier] = "loading";

            return { ...state, documentsState };
        }

        case ActionType.SetPrismicDocument: {
            const documents = { ...state.documents };
            const documentsState = { ...state.documentsState };
            const identifier = `${action.payload.documentType}-${action.payload.locale}`;
            documents[identifier] = action.payload.document;
            documentsState[identifier] = "loaded";

            return { ...state, documents, documentsState };
        }

        case ActionType.SetPrismicDocumentError: {
            const documents = { ...state.documents };
            const documentsState = { ...state.documentsState };
            const documentsErrors = { ...state.documentsErrors };
            const identifier = `${action.payload.documentType}-${action.payload.locale}`;
            delete documents[identifier];
            documentsState[identifier] = "failed";
            documentsErrors[identifier] = action.payload.error;

            return { ...state, documents, documentsState, documentsErrors };
        }

        case ActionType.SetLocale:
            return {
                ...state,
                currentLocale: action.payload.locale,
            };

        default:
            return state;
    }
};

export const authenticate = (user: BuildwiseUser, isAdmin: boolean): Authenticate => ({
    type: ActionType.Authenticate,
    payload: { user, isAdmin },
});

export const setUseRedirect = (useRedirect: boolean): SetUseRedirect => ({
    type: ActionType.SetUseRedirect,
    payload: { useRedirect },
});

export const login = (onSuccess?: () => void): Login => ({
    type: ActionType.Login,
    payload: { onSuccess },
});

export const logout = (): Logout => ({
    type: ActionType.Logout,
});

export const setFeedbackFormVisibility = (visible: boolean): SetFeedbackFormVisibility => ({
    type: ActionType.SetFeedbackFormVisibility,
    payload: { visible },
});

export const requestPrismicDocument = (documentType: string, locale: ValidLocale): RequestPrismicDocument => ({
    type: ActionType.RequestPrismicDocument,
    payload: {
        documentType,
        locale,
    },
});

export const loadPrismicDocument = (documentType: string, locale: ValidLocale): LoadPrismicDocument => ({
    type: ActionType.LoadPrismicDocument,
    payload: {
        documentType,
        locale,
    },
});

export const setPrismicDocument = (
    documentType: string,
    locale: ValidLocale,
    document: PrismicDocument
): SetPrismicDocument => ({
    type: ActionType.SetPrismicDocument,
    payload: {
        documentType,
        locale,
        document,
    },
});

export const setPrismicDocumentError = (
    documentType: string,
    locale: ValidLocale,
    error: string
): SetPrismicDocumentError => ({
    type: ActionType.SetPrismicDocumentError,
    payload: {
        documentType,
        locale,
        error,
    },
});

export const setLocale = (locale: ValidLocale): SetLocale => ({
    type: ActionType.SetLocale,
    payload: { locale },
});
