import { HTMLAttributes, ReactNode } from "react";
import { usePanelContext } from "./Panel";

import styles from "./Panel.module.css";

export interface PanelBodyProps extends HTMLAttributes<HTMLDivElement> {
    /** Panel body content */
    children: ReactNode;
}

const PanelBody = ({ children, className, ...other }: PanelBodyProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = usePanelContext();
    const activeStyles = [styles.body];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default PanelBody;
