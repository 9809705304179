import { HTMLAttributes, ReactNode } from "react";
import { useModalContext } from "./Modal";

import styles from "./Modal.module.css";

export interface ModalFooterProps extends HTMLAttributes<HTMLDivElement> {
    /** Footer of the modal */
    children: ReactNode;
}

const ModalFooter = ({ children, className, ...other }: ModalFooterProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useModalContext();
    const activeStyles = [styles.footer];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default ModalFooter;
