import { createContext, Dispatch } from "react";

import { HeaderActions } from "./HeaderActions";
import { HeaderState, initialState } from "./HeaderState";

export const HeaderContext = createContext<{
    state: HeaderState;
    dispatch: Dispatch<HeaderActions>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
