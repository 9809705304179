import { InputHTMLAttributes } from "react";

import styles from "./Radio.module.css";

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
    /** If true, mouse events are disabled and the input appears faded. */
    disabled?: boolean;
    /** Label to display next to the radio input. */
    label?: string;
}

const Radio = ({ label, disabled, style, className, ...other }: RadioProps) => {
    const activeStyles = disabled ? [styles.container, styles.disabled] : [styles.container];

    if (className) activeStyles.push(className);

    return (
        <label className={activeStyles.join(" ")} style={style}>
            {label}
            <input {...other} type="radio" />
            <span className={styles.focus}></span>
            <span className={styles.indicator}></span>
        </label>
    );
};

export default Radio;
