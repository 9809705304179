import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { ChevronDown, X, XSquare } from "react-feather";

import Chip from "../../data/Chip/Chip";
import usePrismic from "../../../hooks/usePrismic/usePrismic";

import styles from "./Select.module.css";

export interface SelectOption {
    label: string;
    value: string;
    disabled?: boolean;
}

export interface SelectProps extends HTMLAttributes<HTMLInputElement> {
    /** Determine if selecting multiple options is possible */
    multiselect?: boolean;
    /** Determine if the dropdown list can be filtered */
    filterable?: boolean;
    /** Determine if the input is disabled */
    disabled?: boolean;
    /** Determine if it is possible to clear the active selection using a button */
    clearable?: boolean;
    /** Determine if there should be a top margin or not */
    margin: boolean;
    /** Label text */
    label?: string;
    /** Placeholder text that is displayed when no option has been selected */
    placeholder?: string;
    /** Description text displayed underneath the dropdown */
    description?: string;
    /** Options array */
    options: Array<SelectOption>;
    /** The active selection */
    selection: Array<SelectOption>;
    /** Callback function that is triggered when an element from the dropdown has been selected */
    onChangeSelection: (selection: Array<SelectOption>) => void;
}

interface Coords {
    top: number;
    left: number;
    width: number;
}

const Select = ({
    multiselect,
    disabled,
    clearable,
    margin,
    label,
    description,
    options,
    selection,
    placeholder,
    filterable,
    onChangeSelection,
    className,
    ...other
}: SelectProps) => {
    const [selectedItems, setSelectedItems] = useState<Array<SelectOption>>([]);
    const [coords, setCoords] = useState<Coords>({ top: 0, left: 0, width: 0 });

    const [expanded, setExpanded] = useState(false);
    const [filter, setFilter] = useState("");
    const expand = useRef(false);
    const controlRef = useRef<HTMLDivElement>(null);
    const optionsRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const filterRef = useRef<string>("");
    const selectedItemsRef = useRef<Array<SelectOption>>([]);

    const [document, { state: prismicState }] = usePrismic("bbriui");

    const activeStyles = disabled ? [styles.select, styles.disabled] : [styles.select];
    if (className) activeStyles.push(className);
    if (!label && margin) activeStyles.push(styles.withoutLabel);

    useEffect(() => {
        window.document.addEventListener("click", handleClickOutsideSelect);
        window.document.addEventListener("keyup", handleEscapeKey);
        window.addEventListener("blur", handleWindowEvents);
        window.addEventListener("resize", handleWindowEvents);

        return () => {
            window.document.removeEventListener("click", handleClickOutsideSelect);
            window.document.removeEventListener("keyup", handleEscapeKey);
            window.removeEventListener("blur", handleWindowEvents);
            window.removeEventListener("resize", handleWindowEvents);
        };
    }, []);

    useEffect(() => {
        filterRef.current = filter;
    }, [filter]);

    useEffect(() => {
        selectedItemsRef.current = selectedItems;
    }, [selectedItems]);

    useEffect(() => {
        setSelectedItems(selection);

        if (filterable && !multiselect) {
            if (selection.length > 0) {
                setFilter(selection[0].label);
            } else {
                setFilter("");
            }
        }
    }, [selection]);

    useEffect(() => {
        if (expanded && controlRef.current) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItems]);

    const handleClickOutsideSelect = (e: MouseEvent): void => {
        let element: HTMLElement | null = e.target as HTMLElement;
        while (element !== null) {
            if (element === controlRef.current || element === optionsRef.current || element === inputRef.current) {
                if (!expand.current) {
                    expand.current = true;
                    setExpanded(true);
                }

                e.stopPropagation();
                return;
            }

            element = element.parentElement;
        }

        if (expand.current) closeDropdown();
    };

    const handleEscapeKey = (e: KeyboardEvent) => {
        if (e.key === "Escape" && expand.current) closeDropdown();
    };

    const handleWindowEvents = () => {
        if (expand.current) closeDropdown();
    };

    const closeDropdown = () => {
        setExpanded(false);
        expand.current = false;

        if (filterable) {
            if (multiselect) {
                setFilter("");
                return;
            }

            setFilter(selectedItemsRef.current[0]?.label ?? "");
        }
    };

    const onClickSelectBox = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!expanded && controlRef.current) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }
    };

    const onClickSelectItem = (e: React.MouseEvent<HTMLLIElement>, item: SelectOption) => {
        // e.stopPropagation();

        if (!controlRef.current) return;
        if (!expanded) {
            const rect = controlRef.current.getBoundingClientRect();
            setCoords({
                left: rect.x,
                top: rect.y + window.scrollY + rect.height,
                width: rect.width - 2,
            });
        }

        if (multiselect) {
            let selected = [...selectedItems];
            if (!selected.find((x) => x === item)) {
                selected.push(item);
            }

            setSelectedItems(selected);
            onChangeSelection(selected);
        } else {
            if (filterable) setFilter(item.label);
            setSelectedItems([item]);
            setExpanded(false);
            onChangeSelection([item]);
        }
    };

    const onRemoveSelectedItem = (e: React.MouseEvent<HTMLElement>, item: SelectOption) => {
        // e.stopPropagation();

        const selected = selectedItems.filter((x) => x !== item);
        setSelectedItems(selected);
        onChangeSelection(selected);
    };

    const onClearSelection = (e: React.MouseEvent<SVGElement>) => {
        // e.stopPropagation();
        setFilter("");
        setSelectedItems([]);
        onChangeSelection([]);
    };

    const renderSelection = () => {
        if (!selectedItems || selectedItems.length === 0) {
            if (filterable) return null;
            return <span className={styles.placeholder}>{placeholder}</span>;
        }

        if (multiselect) {
            return (
                <span className={styles.multiselect_items}>
                    {selectedItems.map((item, index) => (
                        <Chip key={index} endIcon={<XSquare />} endIconAction={(e) => onRemoveSelectedItem(e, item)}>
                            {item?.label}
                        </Chip>
                    ))}
                </span>
            );
        } else {
            if (filterable) return null;
            return <span className={styles.selection}>{selectedItems[0].label}</span>;
        }
    };

    const renderInput = () => {
        if (!filterable) return null;

        return (
            <input
                type="text"
                className={styles.filter}
                placeholder={placeholder}
                value={filter}
                onChange={(e) => setFilter(e.currentTarget.value)}
                autoComplete="off"
                ref={inputRef}
            />
        );
    };

    const renderOptions = () => {
        let items = options;

        if (filterable && filter.length > 0 && !selectedItems.some((x) => x.label === filter)) {
            items = items.filter((x) => x.label.toLowerCase().includes(filter.toLowerCase()));
        }

        if (multiselect) {
            items = items.filter((x) => !selectedItems.some((y) => x.value === y.value));
        }

        return (
            <ul>
                {items.map((item, i) => (
                    <li
                        key={i}
                        className={item.disabled ? styles.disabled : undefined}
                        onClick={(e) => onClickSelectItem(e, item)}
                        title={item.label}
                    >
                        {item.label}
                    </li>
                ))}

                {items.length === 0 && (
                    <li className={styles.disabled}>{document!.data.generic_input_select_no_options_available}</li>
                )}
            </ul>
        );
    };

    if (prismicState !== "loaded") return null;

    return (
        <div className={activeStyles.join(" ")}>
            {label && <label>{label}</label>}
            <div
                className={expanded ? [styles.input, styles.focused].join(" ") : styles.input}
                onClick={(e) => onClickSelectBox(e)}
                ref={controlRef}
                {...other}
            >
                <div className={styles.input_container}>
                    {renderSelection()}
                    {renderInput()}
                </div>

                <div className={clearable ? [styles.controls, styles.clearable].join(" ") : styles.controls}>
                    {clearable && selectedItems && selectedItems.length > 0 && <X onClick={onClearSelection} />}
                    <ChevronDown />
                </div>
            </div>

            {expanded && (
                <div
                    ref={optionsRef}
                    className={styles.dropdown}
                    style={{
                        // left: coords.left,
                        // top: coords.top,
                        width: coords.width,
                    }}
                >
                    {renderOptions()}
                </div>
            )}

            {description && <span className={styles.description}>{description}</span>}
        </div>
    );
};

export default Select;
