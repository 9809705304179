import { useContext, useEffect } from "react";
import * as prismic from "@prismicio/client";
import { PrismicDocument } from "@prismicio/types";

import { BuildwiseContext } from "./BuildwiseContext";
import { DocumentState, ValidLocale } from "./BuildwiseState";
import { loadPrismicDocument, setPrismicDocument, setPrismicDocumentError } from "./BuildwiseReducer";

const repositoryName = "bbri";
const client = prismic.createClient(repositoryName, {
    accessToken:
        "MC5ZT0xLcVJJQUFDSUF0S1h3.H3vvv71177-9eg1K77-977-9UEvvv70N77-9TSvvv71o77-977-977-977-977-977-9H--_vSzvv73vv73vv73vv70",
});

const uiPackageDocumentType = "bbriui";
export const locales: ValidLocale[] = ["nl-be", "fr-be"];

export const isAlreadyRequested = (
    documentsState: Record<string, DocumentState>,
    documentType: string,
    locale: ValidLocale
): boolean => {
    const identifier = `${documentType}-${locale}`;
    return ["loading", "loaded", "error"].indexOf(documentsState[identifier]) > -1;
};

const getDocumentType = (identifier: string) => {
    const documentType: string = identifier.substring(0, identifier.length - 6);
    const locale: ValidLocale = identifier.slice(-5) as ValidLocale;

    return {
        documentType,
        locale,
    };
};

const LocaleHandler = () => {
    const { state, dispatch } = useContext(BuildwiseContext);

    useEffect(() => {
        if (!client) return;
        locales.forEach((locale) => {
            loadDocument(uiPackageDocumentType, locale);
        });
    }, [client]);

    useEffect(() => {
        const documents = Object.keys(state.documentsState);
        documents.forEach((document) => {
            const currentState = state.documentsState[document];
            if (currentState === "idle") {
                const { documentType, locale } = getDocumentType(document);
                loadDocument(documentType, locale);
            }
        });
    }, [state.documentsState]);

    const loadDocument = (documentType: string, locale: ValidLocale): void => {
        if (isAlreadyRequested(state.documentsState, documentType, locale)) return;

        dispatch(loadPrismicDocument(documentType, locale));
        client
            .getSingle(documentType, { lang: locale })
            .then((response) => handlePrismicResponse(documentType, locale, response))
            .catch((error) => handlePrismicErrorResponse(documentType, locale, error));
    };

    const handlePrismicResponse = (
        documentType: string,
        locale: ValidLocale,
        response: PrismicDocument<Record<string, any>, string, string>
    ) => {
        dispatch(setPrismicDocument(documentType, locale, response));
    };

    const handlePrismicErrorResponse = (documentType: string, locale: ValidLocale, error: string) => {
        dispatch(setPrismicDocumentError(documentType, locale, error));
    };

    return null;
};

export default LocaleHandler;
