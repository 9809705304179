import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import styles from "./Portal.module.css";

export interface PortalProps {
    children: ReactNode;
}

const Portal = ({ children }: PortalProps) => {
    const containerRef = useRef<HTMLDialogElement>();
    const container = document.createElement("dialog");
    const [, forceRender] = useState<number>(0);

    useEffect(() => {
        container.setAttribute("id", `portal-root-${Math.floor(Math.random() * 10000)}`);
        container.setAttribute("class", styles.portal);
        container.addEventListener("cancel", (e) => {
            e.preventDefault();
        });

        document.body.appendChild(container);

        return () => {
            if (containerRef.current) document.body.removeChild(containerRef.current);
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) return;
        containerRef.current = container;
        forceRender((x) => x + 1);
        container.showModal();
    }, [container]);

    return containerRef.current ? createPortal(children, containerRef.current) : null;
};

export default Portal;
