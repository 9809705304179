import { ReactNode } from "react";
import styles from "./Button.module.css";

export interface ButtonEndIconProps {
    children: ReactNode;
}

const ButtonEndIcon = ({ children }: ButtonEndIconProps) => {
    return <span className={[styles.icon, styles.end].join(" ")}>{children}</span>;
};

export default ButtonEndIcon;
