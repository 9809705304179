import { ReactNode } from "react";
import styles from "./Button.module.css";

export interface ButtonStartIconProps {
    children: ReactNode;
}

const ButtonStartIcon = ({ children }: ButtonStartIconProps) => {
    return <span className={[styles.icon, styles.start].join(" ")}>{children}</span>;
};

export default ButtonStartIcon;
