import { MouseEvent, ReactNode } from "react";
import styles from "./Chip.module.css";

export interface ChipEndIconProps {
    children: ReactNode;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const ChipEndIcon = ({ children, onClick }: ChipEndIconProps) => {
    const activeStyles = [styles.icon, styles.end];
    if (onClick) activeStyles.push(styles.action);

    return (
        <span className={activeStyles.join(" ")} onClick={onClick}>
            {children}
        </span>
    );
};

export default ChipEndIcon;
