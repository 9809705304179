import { createContext, Dispatch } from "react";

import { ToastActions } from "./ToastActions";
import { ToastState, initialState } from "./ToastState";

export const ToastContext = createContext<{
    state: ToastState;
    dispatch: Dispatch<ToastActions>;
}>({
    state: initialState,
    dispatch: () => undefined,
});
