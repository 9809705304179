import { useContext } from "react";
import { ToastContext } from "./ToastContext";
import ToastObject from "./Toast";

import styles from "./Toast.module.css";

const ToastRenderer = () => {
    const { state } = useContext(ToastContext);

    return (
        <div className={styles.container}>
            {state.toasts.map((toast, key) => (
                <ToastObject key={key} toast={toast} />
            ))}
        </div>
    );
};

export default ToastRenderer;
