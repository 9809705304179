import { ReactNode } from "react";
import { ActionType, AddBreadcrumb, HeaderActions, RemoveBreadcrumb } from "./HeaderActions";
import { HeaderState } from "./HeaderState";

export const HeaderReducer = (state: HeaderState, action: HeaderActions): HeaderState => {
    switch (action.type) {
        case ActionType.AddBreadcrumb: {
            const breadcrumbs = { ...state.breadcrumbs };
            breadcrumbs[action.payload.id] = action.payload.breadcrumb;

            return {
                ...state,
                breadcrumbs,
            };
        }

        case ActionType.RemoveBreadcrumb: {
            const breadcrumbs = { ...state.breadcrumbs };
            delete breadcrumbs[action.payload.id];

            return {
                ...state,
                breadcrumbs,
            };
        }

        default:
            return state;
    }
};

export const addBreadcrumb = (id: string, breadcrumb?: ReactNode): AddBreadcrumb => ({
    type: ActionType.AddBreadcrumb,
    payload: { id, breadcrumb },
});

export const removeBreadcrumb = (id: string): RemoveBreadcrumb => ({
    type: ActionType.RemoveBreadcrumb,
    payload: { id },
});
