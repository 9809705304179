import { PrismicDocument } from "@prismicio/types";
import { ValidLocale } from "./BuildwiseState";
import { BuildwiseUser } from "./BuildwiseUser";

export enum ActionType {
    // Authentication actions
    Authenticate,
    SetUseRedirect,
    Login,
    Logout,

    // General actions
    SetFeedbackFormVisibility,

    // Localisation
    RequestPrismicDocument,
    LoadPrismicDocument,
    SetPrismicDocument,
    SetPrismicDocumentError,
    SetLocale,
}

export interface Authenticate {
    type: ActionType.Authenticate;
    payload: { user: BuildwiseUser; isAdmin: boolean };
}

export interface SetUseRedirect {
    type: ActionType.SetUseRedirect;
    payload: { useRedirect: boolean };
}
export interface Login {
    type: ActionType.Login;
    payload: { onSuccess?: () => void };
}

export interface Logout {
    type: ActionType.Logout;
}

export interface SetFeedbackFormVisibility {
    type: ActionType.SetFeedbackFormVisibility;
    payload: { visible: boolean };
}

export interface RequestPrismicDocument {
    type: ActionType.RequestPrismicDocument;
    payload: {
        documentType: string;
        locale: ValidLocale;
    };
}

export interface LoadPrismicDocument {
    type: ActionType.LoadPrismicDocument;
    payload: {
        documentType: string;
        locale: ValidLocale;
    };
}

export interface SetPrismicDocument {
    type: ActionType.SetPrismicDocument;
    payload: {
        documentType: string;
        locale: ValidLocale;
        document: PrismicDocument;
    };
}

export interface SetPrismicDocumentError {
    type: ActionType.SetPrismicDocumentError;
    payload: {
        documentType: string;
        locale: ValidLocale;
        error: string;
    };
}

export interface SetLocale {
    type: ActionType.SetLocale;
    payload: { locale: ValidLocale };
}

export type BuildwiseActions =
    | Authenticate
    | SetUseRedirect
    | Login
    | Logout
    | SetFeedbackFormVisibility
    | RequestPrismicDocument
    | LoadPrismicDocument
    | SetPrismicDocument
    | SetPrismicDocumentError
    | SetLocale;
