import { HTMLAttributes } from "react";

import styles from "./Table.module.css";

export interface TableProps extends HTMLAttributes<HTMLTableElement> {}

const Table = ({ className, children, ...rest }: TableProps) => {
    const activeStyles = [styles.table];
    if (className) activeStyles.push(className);

    return (
        <table className={activeStyles.join(" ")} {...rest}>
            {children}
        </table>
    );
};

export default Table;
