import { createContext, HTMLAttributes, ReactNode, useContext } from "react";

import styles from "./Panel.module.css";

const PanelContext = createContext({});

export const usePanelContext = () => {
    const context = useContext(PanelContext);
    if (!context) throw new Error(`Panel compound components cannot be rendered outside the Panel component`);

    return context;
};

export interface PanelProps extends HTMLAttributes<HTMLDivElement> {
    /** Child Panel components */
    children: ReactNode;
}

const Panel = ({ children, className, ...other }: PanelProps) => {
    const activeStyles = [styles.panel];
    if (className) activeStyles.push(className);

    return (
        <PanelContext.Provider value={{}}>
            <div className={activeStyles.join(" ")} {...other}>
                {children}
            </div>
        </PanelContext.Provider>
    );
};

export default Panel;
