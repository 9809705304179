import { useContext, createContext } from "react";

export const QuestionBoxContext = createContext({});

export const useQuestionBoxContext = () => {
    const context = useContext(QuestionBoxContext);
    if (!context)
        throw new Error(`QuestionBox compound components cannot be rendered outside the QuestionBox component.`);

    return context;
};
