import { useContext, useEffect, useRef } from "react";
import { Toast as ToastObject } from "./ToastState";
import { AlertTriangle, CheckCircle, HelpCircle, Info, Slash, X } from "react-feather";

import { ToastContext } from "./ToastContext";

import styles from "./Toast.module.css";
import { removeToast } from "./ToastReducer";

export interface ToastProps {
    toast: ToastObject;
}

const Toast = ({ toast: { id, type, title, message, autoClose } }: ToastProps) => {
    const { dispatch } = useContext(ToastContext);
    const autoCloseTimeout: { current: NodeJS.Timeout | null } = useRef(null);

    const activeStyles = [styles.root, styles[type]];

    useEffect(() => {
        autoCloseTimeout.current = setTimeout(() => onClose(), autoClose);
    }, []);

    const onClose = () => {
        dispatch(removeToast(id));
    };

    const getIcon = () => {
        switch (type) {
            case "question":
                return <HelpCircle />;
            case "info":
                return <Info />;
            case "warning":
                return <AlertTriangle />;
            case "error":
                return <Slash />;
            case "success":
                return <CheckCircle />;
        }
    };

    return (
        <div className={activeStyles.join(" ")}>
            <div className={styles.header}>
                <h2>
                    {getIcon()} {title}
                </h2>
                <div className={styles.close}>
                    <X size="24" onClick={onClose} />
                </div>
            </div>

            <div className={styles.content}>{message}</div>
        </div>
    );
};

export default Toast;
