import { HTMLAttributes, ReactNode } from "react";
import { useStepperContext } from "./StepperContext";

import styles from "./Stepper.module.css";

export interface StepProps extends StepPropsInternal {
    /** Elements that form the content of this step. */
    children: ReactNode;
    /** Title of the step */
    label: string;
}

export interface StepPropsInternal extends HTMLAttributes<HTMLLIElement> {
    /** Internal use */
    isInternal?: true;
    /** Internal use */
    step?: number;
    /** Internal use */
    selected?: boolean;
    /** Internal use */
    completed?: boolean;
    /** Internal use */
    onSelectStep?: () => void;
}

const Step = ({
    isInternal,
    step,
    selected,
    completed,
    onSelectStep,
    label,
    className,
    children,
    ...other
}: StepProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useStepperContext();
    const activeStyles = [];
    if (selected) activeStyles.push(styles.active);
    if (completed) activeStyles.push(styles.completed);

    const handleSelectStep = () => {
        if (selected) return;
        onSelectStep && onSelectStep();
    };

    return (
        <li onClick={() => handleSelectStep()} className={activeStyles.join(" ")} {...other}>
            <p>
                <span className={styles.identifier}>{(step ?? 0) + 1}</span>
                <span>{label}</span>
            </p>
        </li>
    );
};

export default Step;
