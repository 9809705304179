export interface Toast {
    id: string;
    type: "question" | "info" | "warning" | "success" | "error";
    title: string;
    message: string;
    autoClose: number;
}

export interface ToastState {
    toasts: Array<Toast>;
}

export const initialState: ToastState = {
    toasts: [],
};
