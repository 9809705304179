import { HTMLAttributes, ReactNode } from "react";
import { useModalContext } from "./Modal";

import styles from "./Modal.module.css";

export interface ModalBodyProps extends HTMLAttributes<HTMLDivElement> {
    /** Content of the modal */
    children: ReactNode;
    /** If true, adds a color accent to the top of the content. */
    useAccent?: boolean;
}

const ModalBody = ({ useAccent, children, className, ...other }: ModalBodyProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useModalContext();
    const activeStyles = [styles.body];
    if (useAccent) activeStyles.push(styles.accent);
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            <div className={styles.spacer}>{children}</div>
        </div>
    );
};

export default ModalBody;
