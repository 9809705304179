import { useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { ChevronRight, Home } from "react-feather";
import { HeaderContext } from "../../surface/Header/HeaderContext";

import styles from "./Breadcrumb.module.css";

export interface BreadcrumbRendererProps {
    homeAction?: () => void;
}

const BreadcrumbRenderer = ({ homeAction }: BreadcrumbRendererProps) => {
    const { state } = useContext(HeaderContext);
    const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });

    return (
        <div className={styles.wrapper}>
            <div className={styles.breadcrumbs}>
                <span
                    id="breadcrumb-home"
                    className={styles.link}
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                    onClick={() => homeAction && homeAction()}
                >
                    <Home className={styles.home} style={{ pointerEvents: "none" }} />
                </span>

                {isSmallScreen && Object.keys(state.breadcrumbs).length > 2 && (
                    <span className={styles.breadcrumb}>
                        <ChevronRight className={styles.icon} />
                        <span>...</span>
                    </span>
                )}

                {Object.keys(state.breadcrumbs).map((id, index) => {
                    if (isSmallScreen && Object.keys(state.breadcrumbs).length - 2 > index) return null;
                    const breadcrumb = state.breadcrumbs[id];
                    return (
                        breadcrumb && (
                            <span key={index} className={styles.breadcrumb}>
                                <ChevronRight className={styles.icon} />
                                <span className={styles.link}>{breadcrumb}</span>
                            </span>
                        )
                    );
                })}
            </div>
        </div>
    );
};

export default BreadcrumbRenderer;
