import { ChangeEvent, FocusEvent, forwardRef, HTMLAttributes, useImperativeHandle, useRef, useState } from "react";
import { Search } from "react-feather";
import usePrismic from "../../../hooks/usePrismic/usePrismic";

import styles from "./Text.module.css";

export interface TextProps extends HTMLAttributes<HTMLInputElement> {
    /** Type of the input. */
    type: "text" | "search";
    /** If true, mouse events are disabled and the input appears faded. */
    disabled?: boolean;
    /** The value of the input. */
    value: string;
    /** Text to display next to the input. */
    legend?: string;
    /** If true, text will be display to indicate that the field is required. */
    required?: boolean;
    /** Callback function that is triggered when the input loses focus. */
    onValidate?: (value: string) => boolean;
    /** Error message that is displayed when `onValidate` callback returns false; */
    validationError?: string;
}

export interface PasswordProps extends HTMLAttributes<HTMLInputElement> {
    type: "password";
    disabled?: boolean;
    value: string;
    legend?: string;
    required?: boolean;
}

export interface TextareaProps extends HTMLAttributes<HTMLTextAreaElement> {
    type: "textarea";
    disabled?: boolean;
    value: string;
    legend?: string;
    required?: boolean;
}

export interface TextHandle {
    clearError: () => void;
    showError: () => void;
}

const Text = forwardRef<TextHandle, TextProps | PasswordProps | TextareaProps>(
    ({ disabled, required, legend, className, ...other }, forwardedRef) => {
        const input = useRef<HTMLInputElement>(null);
        const [isValid, setIsValid] = useState(true);
        const [document, { state: prismicState }] = usePrismic("bbriui");

        useImperativeHandle(
            forwardedRef,
            () => ({
                clearError: () => setIsValid(true),
                showError: () => setIsValid(false),
            }),
            []
        );

        const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
            if (other.type === "text" || other.type === "search") {
                other.onChange && other.onChange(e);
                if (isValid) return;
                if (other.onValidate) setIsValid(other.onValidate(e.target.value));
            }
        };

        const onBlurEvent = (e: FocusEvent<HTMLInputElement>) => {
            if (other.type === "text" || other.type === "search") {
                other.onBlur && other.onBlur(e);
                if (other.onValidate) setIsValid(other.onValidate(e.target.value));
            }
        };

        const renderRequired = () => {
            if (required)
                return <span className={styles.required}>{document!.data.generic_input_label_required_field}</span>;

            return null;
        };

        const renderInputField = () => {
            const activeStyles = disabled ? [styles.main, styles.disabled] : [styles.main];

            switch (other.type) {
                case "search": {
                    const { validationError, onValidate, ...rest } = other;
                    const inputStyles = isValid ? [styles.input] : [styles.input, styles.invalid];

                    if (className) inputStyles.push(className);

                    return (
                        <div className={activeStyles.join(" ")} style={{ display: "flex" }}>
                            <Search
                                style={{
                                    position: "absolute",
                                    alignSelf: "center",
                                    paddingLeft: 15,
                                    color: "rgba(0, 135, 183, 1)",
                                }}
                            />
                            <input
                                {...rest}
                                onChange={(e) => onChangeEvent(e)}
                                onBlur={(e) => onBlurEvent(e)}
                                className={inputStyles.join(" ")}
                                type={other.type}
                                ref={input}
                                style={{ paddingLeft: 48 }}
                            />
                            {legend !== undefined && legend !== "" && <span className={styles.legend}>{legend}</span>}
                            {renderRequired()}
                            {!isValid && validationError && <span className={styles.error}>{validationError}</span>}
                        </div>
                    );
                }

                case "text": {
                    const { validationError, onValidate, ...rest } = other;
                    const inputStyles = isValid ? [styles.input] : [styles.input, styles.invalid];

                    if (className) inputStyles.push(className);

                    return (
                        <div className={activeStyles.join(" ")}>
                            <input
                                {...rest}
                                onChange={(e) => onChangeEvent(e)}
                                onBlur={(e) => onBlurEvent(e)}
                                className={inputStyles.join(" ")}
                                type={other.type}
                                ref={input}
                            />
                            {legend !== undefined && legend !== "" && <span className={styles.legend}>{legend}</span>}
                            {renderRequired()}
                            {!isValid && validationError && <span className={styles.error}>{validationError}</span>}
                        </div>
                    );
                }

                case "password": {
                    const inputStyles = [styles.input];

                    if (className) inputStyles.push(className);

                    return (
                        <div className={activeStyles.join(" ")}>
                            <input {...other} className={inputStyles.join(" ")} type="password" autoComplete="off" />
                            {legend !== undefined && legend !== "" && <span className={styles.legend}>{legend}</span>}
                            {renderRequired()}
                        </div>
                    );
                }

                case "textarea": {
                    const { type, ...rest } = other;
                    const inputStyles = [styles.textarea];

                    if (className) inputStyles.push(className);

                    return (
                        <div className={activeStyles.join(" ")}>
                            <textarea {...rest} className={inputStyles.join(" ")} autoComplete="off" />
                            {renderRequired()}
                        </div>
                    );
                }

                default:
                    return null;
            }
        };

        return prismicState === "loaded" ? renderInputField() : null;
    }
);

export default Text;
