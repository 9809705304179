import { ReactNode, useReducer, useRef } from "react";
import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import { BuildwiseApplication } from "../BuildwiseApplication/BuildwiseApplication";
import BuildwiseAuthenticationHandler from "../BuildwiseAuthenticationHandler/BuildwiseAuthenticationHandler";
import LocaleHandler from "./LocaleHandler";

import { BuildwiseContext } from "./BuildwiseContext";
import { BuildwiseReducer } from "./BuildwiseReducer";
import { initialState } from "./BuildwiseState";

import { ToastContext } from "../../feedback/Toast/ToastContext";
import { ToastReducer } from "../../feedback/Toast/ToastReducer";
import { initialState as initialToastState } from "../../feedback/Toast/ToastState";

export interface BuildwiseProviderProps {
    instance: BuildwiseApplication;
    children: ReactNode;
}

const BuildwiseProvider = ({ instance, children }: BuildwiseProviderProps) => {
    const [state, dispatch] = useReducer(BuildwiseReducer, { ...initialState, instance });
    const [toastState, toastDispatch] = useReducer(ToastReducer, { ...initialToastState });
    const msalInstanceRef = useRef<IPublicClientApplication>(new PublicClientApplication(instance.msalConfiguration));

    return (
        <BuildwiseContext.Provider value={{ state, dispatch }}>
            <ToastContext.Provider value={{ state: toastState, dispatch: toastDispatch }}>
                <MsalProvider instance={msalInstanceRef.current}>
                    <LocaleHandler />
                    <BuildwiseAuthenticationHandler>{children}</BuildwiseAuthenticationHandler>
                </MsalProvider>
            </ToastContext.Provider>
        </BuildwiseContext.Provider>
    );
};

export default BuildwiseProvider;
