import { createContext, HTMLAttributes, ReactNode, useContext } from "react";

import styles from "./Content.module.css";

const ContentContext = createContext({});

export const useContentContext = () => {
    const context = useContext(ContentContext);
    if (!context) throw new Error(`Content compound components cannot be rendered outside the Content component`);

    return context;
};

export interface ContentProps extends HTMLAttributes<HTMLDivElement> {
    /** Child content components */
    children: ReactNode;
}

const Content = ({ children, className, ...other }: ContentProps) => {
    const activeStyles = [styles.content];
    if (className) activeStyles.push(className);

    return (
        <ContentContext.Provider value={{}}>
            <div className={activeStyles.join(" ")} {...other}>
                {children}
            </div>
        </ContentContext.Provider>
    );
};

export default Content;
