import { ReactNode } from "react";

export interface HeaderState {
    breadcrumbs: {
        [id: string]: ReactNode | undefined;
    };
}

export const initialState: HeaderState = {
    breadcrumbs: {},
};
