import { InputHTMLAttributes, useEffect, useRef } from "react";

import styles from "./Checkbox.module.css";

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    /** If true, the checkbox appears in an indeterminate state. */
    indeterminate?: boolean;
    /** Label to display next to the checkbox. */
    label?: string;
}

const Checkbox = ({ label, disabled, indeterminate, style, className, ...other }: CheckboxProps) => {
    const checkbox = useRef<HTMLInputElement>(null);
    const activeStyles = disabled ? [styles.container, styles.disabled] : [styles.container];

    if (className) activeStyles.push(className);

    useEffect(() => {
        if (checkbox.current) checkbox.current.indeterminate = indeterminate ?? false;
    }, [indeterminate]);

    return (
        <label className={activeStyles.join(" ")} style={style}>
            {label}
            <input {...other} ref={checkbox} type="checkbox" />
            <span className={styles.focus}></span>
            <span className={styles.indicator}></span>
        </label>
    );
};

export default Checkbox;
