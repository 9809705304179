import { useRef, MouseEvent, ReactNode } from "react";

import styles from "./Backdrop.module.css";

export interface BackdropProps {
    children: ReactNode;
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
}

const Backdrop = ({ onClick, children }: BackdropProps) => {
    const backdropRef = useRef<HTMLDivElement>(null);

    const onHandleClick = (e: MouseEvent<HTMLDivElement>) => {
        if (e.target !== backdropRef.current) return;
        onClick && onClick(e);
    };

    return (
        <div className={styles.backdrop} ref={backdropRef} onClick={onHandleClick}>
            {children}
        </div>
    );
};

export default Backdrop;
