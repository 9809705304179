import { Toast, ToastState } from "./ToastState";
import { ActionType, AddToast, ToastActions, RemoveToast } from "./ToastActions";

export const ToastReducer = (state: ToastState, action: ToastActions): ToastState => {
    switch (action.type) {
        case ActionType.AddToast: {
            const toasts = [...state.toasts];
            toasts.push(action.payload.toast);

            return {
                ...state,
                toasts,
            };
        }

        case ActionType.RemoveToast: {
            return {
                ...state,
                toasts: state.toasts.filter((x) => x.id !== action.payload.id),
            };
        }

        default:
            return state;
    }
};

export const addToast = (toast: Toast): AddToast => ({
    type: ActionType.AddToast,
    payload: { toast },
});

export const removeToast = (id: string): RemoveToast => ({
    type: ActionType.RemoveToast,
    payload: { id },
});
