import { Children, HTMLAttributes, isValidElement, ReactNode, useEffect, useState } from "react";
import { useSelectGroupContext } from "./SelectGroupContext";
import SelectGroupItemDescription from "./SelectGroupItemDescription";
import SelectGroupItemImage from "./SelectGroupItemImage";
import SelectGroupItemTitle from "./SelectGroupItemTitle";

import styles from "./SelectGroup.module.css";

export interface SelectGroupItemProps extends HTMLAttributes<HTMLDivElement> {
    /** Unique combination of `<SelectGroupItemTitle>`, `<SelectGroupItemDescription>` and `<SelectGroupItemImage>` */
    children: ReactNode;
    /** Unique identifier */
    identifier: string;
    /** Internal selection indicator */
    selected?: boolean;
}

interface GroupItems {
    title: ReactNode;
    description: ReactNode;
    image: ReactNode;
}

const SelectGroupItem = ({ identifier, selected, onClick, children, className, ...other }: SelectGroupItemProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useSelectGroupContext();
    const [data, setData] = useState<GroupItems>();
    const activeStyles = [styles.item];
    if (selected) activeStyles.push(styles.selected);
    if (className) activeStyles.push(className);

    useEffect(() => {
        const items: GroupItems = { title: null, description: null, image: null };
        Children.forEach(children, (child) => {
            if (isValidElement(child)) {
                switch (child.type) {
                    case SelectGroupItemTitle: {
                        items.title = child;
                        break;
                    }
                    case SelectGroupItemDescription: {
                        items.description = child;
                        break;
                    }
                    case SelectGroupItemImage: {
                        items.image = child;
                        break;
                    }
                }
            }
        });

        setData(items);
    }, [children]);

    return (
        <div className={activeStyles.join(" ")} onClick={onClick} {...other}>
            <div className={styles.box}>
                {data?.title}
                {data?.description}
            </div>
            {data?.image}
        </div>
    );
};

export default SelectGroupItem;
