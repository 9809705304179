import { createContext, HTMLAttributes, ReactNode, useContext } from "react";

import styles from "./Box.module.css";

const BoxContext = createContext({});

export const useBoxContext = () => {
    const context = useContext(BoxContext);
    if (!context) throw new Error(`Box compound components cannot be rendered outside the Box component.`);

    return context;
};

export interface BoxProps extends HTMLAttributes<HTMLDivElement> {
    /** Adds an orange color accent to the top of the box. */
    useAccent?: boolean;
    /** The content of the box */
    children: ReactNode;
}

const Box = ({ useAccent, className, children, ...other }: BoxProps) => {
    const activeStyles = [styles.box];
    if (useAccent) activeStyles.push(styles.accent);
    if (className) activeStyles.push(className);

    return (
        <BoxContext.Provider value={{}}>
            <div className={activeStyles.join(" ")} {...other}>
                {children}
            </div>
        </BoxContext.Provider>
    );
};

export default Box;
