import { HTMLAttributes, ReactNode, useContext, useLayoutEffect, useReducer } from "react";
import { useMsal } from "@azure/msal-react";
import { AuthenticationResult, EventMessage, EventType, InteractionType } from "@azure/msal-browser";

import { BuildwiseContext } from "../../core/BuildwiseProvider/BuildwiseContext";
import { setUseRedirect } from "../../core/BuildwiseProvider/BuildwiseReducer";

import CookieConsent from "./CookieConsent";
import FeedbackForm from "./FeedbackForm";
import Spinner from "../../feedback/Spinner/Spinner";
import ToastRenderer from "../../feedback/Toast/ToastRenderer";

import { HeaderContext } from "../../surface/Header/HeaderContext";
import { HeaderReducer } from "../../surface/Header/HeaderReducer";
import { initialState } from "../../surface/Header/HeaderState";

import "../../../shared/global.css";
import "../../../shared/typography.css";
import usePrismic from "../../../hooks/usePrismic/usePrismic";

export interface PageProps extends HTMLAttributes<HTMLDivElement> {
    /** Content of the page */
    children: ReactNode;
    /** If true, a feedback icon is displayed on the bottom right of the page */
    feedback?: boolean;
}

const Page = ({ feedback, children, ...other }: PageProps) => {
    const { state, dispatch } = useContext(BuildwiseContext);
    const [headerState, headerDispatch] = useReducer(HeaderReducer, { ...initialState });
    const { instance } = useMsal();
    const [, { state: prismicState, error }] = usePrismic("bbriui");

    // Authentication setup
    useLayoutEffect(() => {
        instance.enableAccountStorageEvents();

        const accounts = instance.getAllAccounts();
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
            instance
                .acquireTokenSilent({
                    scopes: state.instance!.scopes,
                    account: accounts[0],
                })
                .catch((error) => {
                    if (error.name === "InteractionRequiredAuthError") {
                        instance
                            .acquireTokenPopup({
                                scopes: state.instance!.scopes,
                                account: accounts[0],
                                redirectUri: "",
                            })
                            .catch(() => {
                                instance.logoutRedirect();
                            });

                        return;
                    }
                });
        }

        instance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                instance.setActiveAccount(account);

                if (event.interactionType === InteractionType.Redirect) {
                    dispatch(setUseRedirect(true));
                }
            }
        });
    }, []);

    const renderContent = () => {
        switch (prismicState) {
            case "loaded": {
                return (
                    <div {...other}>
                        {children}
                        {feedback && <FeedbackForm />}
                        {state?.instance?.cookies && <CookieConsent />}
                        <ToastRenderer />
                    </div>
                );
            }
            case "idle":
            case "loading":
                return <Spinner />;
            case "failed":
                throw new Error(`Failed to fetch required prismic document: ${error}`);
        }
    };

    return (
        <HeaderContext.Provider value={{ state: headerState, dispatch: headerDispatch }}>
            {renderContent()}
        </HeaderContext.Provider>
    );
};

export default Page;
