import { HTMLAttributes } from "react";
import styles from "./Spinner.module.css";

export interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {}

const Spinner = ({ className, ...other }: SpinnerProps) => {
    const activeStyles = [styles.spinner];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            <div className={styles.first}></div>
            <div className={styles.second}></div>
            <div className={styles.third}></div>
            <div className={styles.fourth}></div>
            <div className={styles.fifth}></div>
        </div>
    );
};

export default Spinner;