import { HTMLAttributes, ReactNode } from "react";
import { ChevronDown } from "react-feather";

import styles from "./Header.module.css";

export interface ApplicationMenuItemProps extends HTMLAttributes<HTMLDivElement> {
    icon: ReactNode;
    label: string;
    children: ReactNode;
}

const ApplicationMenuItem = ({ icon, label, className, children, ...other }: ApplicationMenuItemProps) => {
    const activeStyles = [styles.item];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            <div className={styles.align} style={{ pointerEvents: "none" }}>
                {icon}
                {label}
                {children && <ChevronDown style={{ marginLeft: "4px", marginRight: "0px" }} />}
            </div>
            {children && (
                <div className={styles.submenu}>
                    <div className={styles.dropdown}>
                        <ul>{children}</ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ApplicationMenuItem;
