import { HTMLAttributes, ReactNode } from "react";
import { usePanelContext } from "./Panel";

import styles from "./Panel.module.css";

export interface PanelHeaderProps extends HTMLAttributes<HTMLDivElement> {
    /** Panel header content */
    children: ReactNode;
}

const PanelHeader = ({ children, className, ...other }: PanelHeaderProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = usePanelContext();
    const activeStyles = [styles.header];
    if (className) activeStyles.push(className);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            {children}
        </div>
    );
};

export default PanelHeader;
