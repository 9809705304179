import { useContext } from "react";
import { BuildwiseContext } from "../../components/core/BuildwiseProvider/BuildwiseContext";
import { setLocale } from "../../components/core/BuildwiseProvider/BuildwiseReducer";
import { ValidLocale } from "../../components/core/BuildwiseProvider/BuildwiseState";

export interface UseLanguageHookProps {
    language: ValidLocale;
    setLanguage: (language: ValidLocale) => void;
}

const useLanguage = (): UseLanguageHookProps => {
    const { state: appState, dispatch: appDispatch } = useContext(BuildwiseContext);
    return {
        language: appState.currentLocale,
        setLanguage: (language: ValidLocale) => appDispatch(setLocale(language)),
    };
};

export default useLanguage;
