import { HTMLAttributes, ReactNode } from "react";

import styles from "./IconButton.module.css";

export interface IconButtonProps extends HTMLAttributes<HTMLButtonElement> {
    /** Variant of the button. */
    variant: "primary" | "secondary" | "tertiary";
    /** If true, mouse events are disabled and the button appears faded. */
    disabled?: boolean;
    /** The icon to appear on the button. */
    children: ReactNode;
}

const IconButton = ({ variant, disabled, className, children, ...other }: IconButtonProps) => {
    const classNames = [styles.button, styles[variant]];

    if (disabled) classNames.push(styles.disabled);
    if (className) classNames.push(className);

    return (
        <button className={classNames.join(" ")} {...other}>
            <span className={styles.icon}>{children}</span>
        </button>
    );
};

export default IconButton;
