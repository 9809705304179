import { HTMLAttributes, ReactNode } from "react";
import { useModalContext } from "./Modal";

export interface ModalTitleProps extends HTMLAttributes<HTMLHeadingElement> {
    /** Title of the modal */
    children: ReactNode;
}

const ModalTitle = ({ children, ...other }: ModalTitleProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const _ = useModalContext();

    return <h2 {...other}>{children}</h2>;
};

export default ModalTitle;
