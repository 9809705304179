import { Toast } from "./ToastState";

export enum ActionType {
    AddToast,
    RemoveToast,
}

export interface AddToast {
    type: ActionType.AddToast;
    payload: { toast: Toast };
}

export interface RemoveToast {
    type: ActionType.RemoveToast;
    payload: { id: string };
}

export type ToastActions = AddToast | RemoveToast;
