import { ChangeEvent, FocusEvent, HTMLAttributes, useEffect, useRef, useState } from "react";
import { Plus, Minus } from "react-feather";

import usePrismic from "../../../hooks/usePrismic/usePrismic";

import styles from "./Numeric.module.css";

export interface NumericProps extends HTMLAttributes<HTMLInputElement> {
    /** If true, mouse events are disabled and the input appears faded. */
    disabled?: boolean;
    /** The value of the input. */
    value: string | number;
    /** The maximum value of the input. */
    max?: number;
    /** The minimum value of the input. */
    min?: number;
    /** The increment/decrement size of the buttons. */
    step?: number | "any";
    /** Text to display next to the input. */
    legend?: string;
    /** If true, text will be display to indicate that the field is required. */
    required?: boolean;
}

const Numeric = ({ disabled, style, className, value, min, max, step, required, legend, ...other }: NumericProps) => {
    const input = useRef<HTMLInputElement>(null);
    const [timer, setTimer] = useState<number>();
    const [document, { state: prismicState }] = usePrismic("bbriui");
    const activeStyles = disabled ? [styles.numeric, styles.disabled] : [styles.numeric];

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value")!.set;

    useEffect(() => {
        if (!input.current) return;
        if (!value) {
            input.current.value = "";
            return;
        }

        if (max && Number(value) > max) input.current.value = max?.toString();
        else if (min && Number(value) < min) input.current.value = min.toString();

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const increment = () => {
        if (!input.current) return;

        if (max && max <= Number(input.current.value)) return;
        const actualStep = step === "any" ? 1 : step;
        nativeInputValueSetter!.call(input.current, Number(input.current.value) + (actualStep ?? 1));
        const e = new Event("change", { bubbles: true });
        input.current.dispatchEvent(e);
    };

    const decrement = () => {
        if (!input.current) return;

        if (min && min >= Number(input.current.value)) return;

        const actualStep = step === "any" ? 1 : step;
        nativeInputValueSetter!.call(input.current, Number(input.current.value) - (actualStep ?? 1));
        const e = new Event("change", { bubbles: true });
        input.current.dispatchEvent(e);
    };

    const onInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
        other.onChange && other.onChange(e);
    };

    const onValidateInput = (e: FocusEvent<HTMLInputElement>) => {
        if (!input.current) return;

        if (max && Number(e.target.value) > max) input.current.value = max.toString();
        else if (min && Number(e.target.value) < min) input.current.value = min.toString();

        other.onChange && other.onChange(e);
        other.onBlur && other.onBlur(e);
    };

    const startAction = (action: Function) => {
        timer && clearInterval(timer);
        action();
        const interval = setInterval(action, 250);
        setTimer(interval);
    };

    const stopAction = () => {
        clearInterval(timer);
    };

    return prismicState === "loaded" ? (
        <div className={styles.main}>
            <div className={activeStyles.join(" ")}>
                <input
                    {...other}
                    min={min}
                    max={max}
                    step={step}
                    ref={input}
                    type="number"
                    onChange={onInputChanged}
                    onBlur={onValidateInput}
                    autoComplete="off"
                    value={value}
                    inputMode="decimal"
                />
                <div className={styles.controls}>
                    <button
                        id="numeric-input-increment"
                        onMouseDown={() => startAction(increment)}
                        onMouseUp={stopAction}
                        onMouseLeave={stopAction}
                        tabIndex={-1}
                    >
                        <Plus />
                    </button>
                    <button
                        id="numeric-input-decrement"
                        onMouseDown={() => startAction(decrement)}
                        onMouseUp={stopAction}
                        onMouseLeave={stopAction}
                        tabIndex={-1}
                    >
                        <Minus />
                    </button>
                </div>
                {legend && <span className={styles.legend}>{legend}</span>}
            </div>
            {required && <span className={styles.required}>{document!.data.generic_input_label_required_field}</span>}
        </div>
    ) : null;
};

export default Numeric;
