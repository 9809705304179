import { HTMLAttributes } from "react";

import styles from "./Separator.module.css";

export interface SeparatorProps extends HTMLAttributes<HTMLDivElement> {
    /** Text to display in the separator */
    children?: string;
    /** Background color for the displayed text */
    color?: "yellow" | "green" | "blue" | "purple" | "red" | "orange" | string;
    textColor?: string;
}

const Separator = ({ color, textColor, children, className, ...other }: SeparatorProps) => {
    const activeStyles = [styles.separator];
    const blockStyles = [styles.content];

    if (className) activeStyles.push(className);

    const bgColor = color ?? "orange";
    const style = color?.startsWith("#")
        ? {
              backgroundColor: color,
              color: textColor ?? "#FFFFFF",
          }
        : null;

    if (!style) blockStyles.push(styles[bgColor]);

    return (
        <div className={activeStyles.join(" ")} {...other}>
            <div className={styles.line}></div>
            {children && (
                <div className={blockStyles.join(" ")} style={style ?? {}}>
                    {children}
                </div>
            )}
        </div>
    );
};

export default Separator;
