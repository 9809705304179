import { ReactNode, useContext, useEffect, useRef } from "react";
import { HeaderContext } from "../../surface/Header/HeaderContext";
import { addBreadcrumb, removeBreadcrumb } from "../../surface/Header/HeaderReducer";

export interface BreadcrumbProps {
    id?: string;
    children: ReactNode;
}

const uuid = () =>
    "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });

const Breadcrumb = ({ children }: BreadcrumbProps) => {
    const { dispatch } = useContext(HeaderContext);
    const idRef = useRef<string>(uuid());

    useEffect(() => {
        return () => {
            dispatch(removeBreadcrumb(idRef.current));
        };
    }, []);

    useEffect(() => {
        dispatch(addBreadcrumb(idRef.current, children));
    }, [children]);

    return null;
};

export default Breadcrumb;
