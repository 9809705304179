import { HTMLAttributes, MouseEvent, ReactNode } from "react";
import ChipEndIcon from "./ChipEndIcon";
import ChipStartIcon from "./ChipStartIcon";

import styles from "./Chip.module.css";

export interface ChipProps extends HTMLAttributes<HTMLDivElement> {
    /** Optional icon to be displayed in front of the Chip's content */
    startIcon?: ReactNode;
    /** Optional action to execute when the icon is clicked */
    startIconAction?: (e: MouseEvent<HTMLElement>) => void;
    /** Optional icon to be displayed after the Chip's content */
    endIcon?: ReactNode;
    /** Optional action to execute when the icon is clicked */
    endIconAction?: (e: MouseEvent<HTMLElement>) => void;
    /** The content of the Chip */
    children: ReactNode;
}

const Chip = ({
    startIcon: startIconProp,
    startIconAction,
    endIcon: endIconProp,
    endIconAction,
    children,
    className,
    ...other
}: ChipProps) => {
    const activeStyles = [styles.chip];
    if (className) activeStyles.push(className);

    const startIcon = startIconProp && <ChipStartIcon onClick={startIconAction}>{startIconProp}</ChipStartIcon>;
    const endIcon = endIconProp && <ChipEndIcon onClick={endIconAction}>{endIconProp}</ChipEndIcon>;

    return (
        <span className={activeStyles.join(" ")} {...other}>
            {startIcon}
            {children}
            {endIcon}
        </span>
    );
};

export default Chip;
