import { Children, ReactNode, useContext, useEffect, useState, isValidElement } from "react";
import { ChevronDown, Globe, Grid, HelpCircle, Menu, User, X } from "react-feather";
import { PrismicImage } from "@prismicio/react";
import { asLink, isFilled } from "@prismicio/helpers";

import { BuildwiseContext } from "../../core/BuildwiseProvider/BuildwiseContext";
import { setFeedbackFormVisibility, setLocale } from "../../core/BuildwiseProvider/BuildwiseReducer";

import useAuthentication from "../../../hooks/useAuthentication/useAuthentication";
import usePrismic from "../../../hooks/usePrismic/usePrismic";

import ApplicationTitle from "./ApplicationTitle";
import ApplicationMenuItem from "./ApplicationMenuItem";
import BreadcrumbRenderer from "../../navigation/Breadcrumb/BreadcrumbRenderer";

import "../../../shared/typography.css";
import styles from "./Header.module.css";

interface HeaderProps {
    /** Header elements building the title and app specific menu options. */
    children: ReactNode;
    /** Function to trigger when the "Home"-breadcrumb or logo is clicked. */
    homeAction?: () => void;
    /** Option to hide the login item in the navigation */
    disableLogin?: boolean;
}

interface MenuItem {
    id: string;
    label: string | null;
    element?: ReactNode;
    icon?: ReactNode;
    items?: Array<MenuItem>;
    action?: () => void;
    href?: string;
}

interface HeaderElements {
    title?: ReactNode;
    menu?: ReactNode[];
}

const Header = ({ disableLogin, homeAction, children }: HeaderProps) => {
    const { state: appState, dispatch: appDispatch } = useContext(BuildwiseContext);

    const [menu, setMenu] = useState<Array<MenuItem>>([]);
    const [appHeader, setAppHeader] = useState<HeaderElements>();
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    const { isAuthenticated, user, login, logout } = useAuthentication();
    const [document, { state: prismicState }] = usePrismic("bbriui");
    const [aboutNavigationDocument, { state: aboutState }] = usePrismic(appState.instance!.prismic.documentType);

    const [aboutItem, setAboutItem] = useState<MenuItem>();

    useEffect(() => {
        if (!aboutNavigationDocument || aboutState !== "loaded") return;

        const hasText = isFilled.keyText(
            aboutNavigationDocument.data[appState.instance!.prismic.aboutApplicationItemKey]
        );
        const hasLink = isFilled.link(aboutNavigationDocument.data[appState.instance!.prismic.aboutApplicationUrlKey]);
        if (!hasText || !hasLink) return;
        setAboutItem({
            id: "site-nav-help-about",
            label: aboutNavigationDocument.data[appState.instance!.prismic.aboutApplicationItemKey],
            href: asLink(aboutNavigationDocument.data[appState.instance!.prismic.aboutApplicationUrlKey]) ?? "",
        });
    }, [aboutNavigationDocument]);

    useEffect(() => {
        if (!appState.instance || prismicState !== "loaded") return;

        let currentLanguage: string;
        let otherLanguage: string;
        let otherLocale: string;
        let changeLanguageAction: () => void;
        if (appState.currentLocale === "nl-be") {
            currentLanguage = document!.data.navigation_language_dutch_item;
            otherLanguage = document!.data.navigation_language_french_item;
            otherLocale = "fr-be";
            changeLanguageAction = () => appDispatch(setLocale("fr-be"));
        } else {
            currentLanguage = document!.data.navigation_language_french_item;
            otherLanguage = document!.data.navigation_language_dutch_item;
            otherLocale = "nl-be";
            changeLanguageAction = () => appDispatch(setLocale("nl-be"));
        }

        const menu: Array<MenuItem> = [
            {
                id: "site-nav-applications",
                icon: <Grid className={styles.icon} />,
                label: document!.data.navigation_applications_item,
                href: asLink(document!.data.navigation_applications_url) ?? "",
            },
            {
                id: "site-nav-help",
                icon: <HelpCircle className={styles.icon} />,
                label: document!.data.navigation_help_contact_item,
                items: [
                    {
                        id: "site-nav-help-feedback",
                        label: document!.data.navigation_help_contact_feedback_subitem,
                        action: () => appDispatch(setFeedbackFormVisibility(true)),
                    },
                    {
                        id: "site-nav-help-privacy",
                        label: document!.data.navigation_help_contact_privacy_subitem,
                        href: asLink(document!.data.navigation_help_contact_privacy_url) ?? "",
                    },
                    {
                        id: "site-nav-help-disclaimer",
                        label: document!.data.navigation_help_contact_disclaimer_subitem,
                        href: asLink(document!.data.navigation_help_contact_disclaimer_url) ?? "",
                    },
                ],
            },
            {
                id: "site-nav-language-select",
                icon: <Globe className={styles.icon} />,
                label: currentLanguage,
                items: [
                    {
                        id: `site-nav-language-select-${otherLocale}`,
                        label: otherLanguage,
                        action: changeLanguageAction,
                    },
                ],
            },
        ];

        if (aboutItem) {
            const helpAndContact = menu.find((x) => x.id === "site-nav-help");
            if (helpAndContact) {
                helpAndContact.items!.unshift(aboutItem);
            }
        }

        if (!Boolean(disableLogin)) {
            let authenticationMenuItem: MenuItem;
            if (isAuthenticated && user && user.id !== "guest-user") {
                authenticationMenuItem = {
                    id: "site-nav-account",
                    icon: <User className={styles.icon} />,
                    label: user!.name,
                    items: [
                        {
                            id: "site-nav-account-logout",
                            label: document!.data.navigation_account_logout_item,
                            action: () => logout(),
                        },
                    ],
                };
            } else {
                authenticationMenuItem = {
                    id: "site-nav-account-login",
                    icon: <User className={styles.icon} />,
                    label: document!.data.navigation_account_login_item,
                    action: () => login(),
                };
            }

            menu.push(authenticationMenuItem);
        }

        setMenu(menu);
    }, [document, isAuthenticated, aboutItem, user]);

    useEffect(() => {
        const header: HeaderElements = {};
        Children.forEach(children, (child, index) => {
            if (isValidElement(child)) {
                switch (child.type) {
                    case ApplicationTitle: {
                        header.title = child;
                        break;
                    }

                    case ApplicationMenuItem: {
                        if (!header.menu) header.menu = [];
                        header.menu.push(child);
                        break;
                    }
                }
            }
        });

        setAppHeader(header);
    }, [children]);

    useEffect(() => {
        window.document.body.classList.toggle("noscroll", mobileMenuVisible);
    }, [mobileMenuVisible]);

    const renderLogo = () => {
        return <PrismicImage field={document!.data.header_logo} widths={[170]} />;
    };

    const renderMenu = () => {
        if (prismicState !== "loaded") return null;

        return menu.map((item, index) => {
            const onClick = () => {
                setMobileMenuVisible(false);
                item.action && item.action();
            };

            return (
                <div key={index} className={styles.item}>
                    <div className={styles.align}>
                        <a
                            id={item.id}
                            className={styles.link}
                            href={item.href}
                            title={item.label ?? ""}
                            target="_blank"
                            rel="noreferrer"
                            onClick={onClick}
                        >
                            {item.icon}
                            <span>{item.label}</span>
                        </a>
                    </div>
                    {item.items && (
                        <>
                            <ChevronDown style={{ margin: "0 0 0 2px" }} className={styles.chevron} />
                            <div className={styles.dropdown}>
                                <ul>
                                    {item.items.map((subItem, index) => {
                                        const onClick = () => {
                                            setMobileMenuVisible(false);
                                            subItem.action && subItem.action();
                                        };

                                        return (
                                            <li key={index} id={subItem.href ? "" : subItem.id} onClick={onClick}>
                                                {subItem.action && <span>{subItem.label}</span>}
                                                {subItem.href && (
                                                    <a
                                                        id={subItem.id}
                                                        href={subItem.href}
                                                        title={subItem.label ?? ""}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        {subItem.label}
                                                    </a>
                                                )}
                                                {!subItem.action && !subItem.href && <p>{subItem.label}</p>}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            );
        });
    };

    return (
        <>
            <div className={styles.header} id="site-header">
                <div className={styles.top}>
                    <div className={styles.wrapper}>
                        <div className={styles.logo} id="site-logo" onClick={homeAction}>
                            {renderLogo()}
                        </div>

                        <div className={styles.navigation} id="site-nav">
                            {renderMenu()}
                        </div>
                    </div>
                </div>

                <div className={styles.bottom} id="site-subheader">
                    <div className={styles.wrapper}>
                        {appHeader?.title}

                        <div className={styles.navigation} id="site-app-nav">
                            {appHeader?.menu}
                        </div>

                        <div className={styles.mobile} id="mobile-nav">
                            {mobileMenuVisible ? (
                                <div onClick={() => setMobileMenuVisible(false)}>
                                    <X size="30" color="#0087b7" />
                                </div>
                            ) : (
                                <div onClick={() => setMobileMenuVisible(true)}>
                                    <Menu size="36" color="#0087b7" />
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <BreadcrumbRenderer homeAction={homeAction} />
            </div>

            {mobileMenuVisible && (
                <div className={styles["mobile-menu"]} onClick={() => setMobileMenuVisible(false)}>
                    {appHeader?.menu && <div className={styles["mobile-app-menu"]}>{appHeader?.menu}</div>}
                    {renderMenu()}
                </div>
            )}
        </>
    );
};

export default Header;
